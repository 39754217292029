<script setup>
import Icon from '@/Components/Icon/index.vue';

const input = defineModel({type: [String, Boolean]});

const props = defineProps({
  value: {
    type: [String, Boolean],
    retuired: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
  },
  id: {
    type: String,
    required: true
  },
  tabIndex: {
    type: Number,
    default: 0
  }
});

const emit = defineEmits('update:value');

const boxChecked = () => {
  emit('update:value', !input.value);
};

</script>

<template>
  <div
 class="flex items-center cursor-pointer" 
           @click="boxChecked">
    <button :id="id" :tabIndex="tabIndex" :aria-checked="input" role="checkbox" class="me-2 h-6 w-6 rounded-full border border-solid border-black flex justify-center items-center flex-none">
      <Icon v-if="input || value" :path="props.type === 'radio' ?  'RadioDot' : 'Check'" class="block flex-none text-primary-500"/>
    </button>
    <span>{{ label }}</span>
  </div>
</template>
