<script setup>
import Checkbox from '@/Components/Input/Checkbox.vue';
import Icon from '@/Components/Icon/index.vue';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';


const props = defineProps({
  label: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  multiSelect: {
    type: Boolean,
    default: false
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  validation: {
    type: Object,
    default: {},
  },
  fieldStyle: {
    type: String,
    required: false,
  },
  searchAble: {
    type: Boolean,
    default: true,
  }
});

const selectedOptions = defineModel({ type: [Array, String]});

const toggleOptions = (event, flag) => {
  if(flag) {
    if(event.shiftKey && event.key == "Tab"){
      return
    }
  }
  focusedOptionIndex.value = 0
  optionsOpen.value = !optionsOpen.value;
  searchField.value = "";
};

const onChecked = (checkboxValue) => {
  if(!props.multiSelect){
    selectedOptions.value = selectedOptions.value == checkboxValue ? '' : checkboxValue;
    return
  }
  const existingIndex = selectedOptions.value.indexOf(checkboxValue);
  if (existingIndex != -1) {
    selectedOptions.value.splice(existingIndex, 1);
    return;
  }
  selectedOptions.value.push(checkboxValue);
};

const optionsOpen = ref(false);

const searchField = ref('');

const filteredOptions = computed(() => {
  return props.options.filter((option) => option.label.toLowerCase().includes(searchField.value.toLowerCase()) || selectedOptions.value.includes(option.value));
});

const noOptionsMatch = computed(() => {
  const noResults = 1 > filteredOptions.value.length;
  return noResults && 2 < searchField.value.length;
});


const showComboOption = (label) => {
  return 2 > searchField.value.length ||
              label.includes(searchField.value);
};


const combobox = ref(null);
const comboOptions = ref(null)
onClickOutside(combobox, (event) => {
  searchField.value = "";
  optionsOpen.value = false;
},
{
  ignore: [comboOptions]
});


const focusedOptionIndex = ref(0);
const listControl = (dir) => {
    if(focusedOptionIndex.value == 0 && dir == -1) {
      return
    }
    if(focusedOptionIndex.value == props.options.length - 1 && dir == 1) {
      return
    }

    focusedOptionIndex.value += dir;

    document.getElementById(`${props.name}-combo-option-${props.options[focusedOptionIndex.value].value}`).focus()
}


const inputLabel = computed(() => {
  if(props.multiSelect) {
    return props.label
  }
  if (selectedOptions.value) {
  return props.options.find((option) => selectedOptions.value == option.value).label;
  }

  return props.placeholder !== null ? props.placeholder : props.label;
})

</script>

<template>
  <div
  ref="combobox"
  class="w-full md:w-fit relative"
  @keydown.esc="optionsOpen = false"
  >
  <label :for="name" :class="{'sr-only': hideLabel}" class="text-xs font-semibold">{{  label }}</label>
    <input
      type="hidden"
      :name="name"
      :value="selectedOptions"
    >
    <div
      tabindex="0"
      class="bg-white border border-solid border-primary-500 rounded-3xl text-primary cursor-pointer w-full relative px-3 py-2"
      :class="[validation.$error ? 'ring-1 ring-red' : '', fieldStyle]"
      @keydown.enter="toggleOptions"
      @click="toggleOptions"
    >
      <div class="relative pointer-events-none flex items-center">
        <label :for="name">{{ inputLabel }}</label>
        <div
          v-if="multiSelect"
          :class="{'opacity-100': selectedOptions.length > 0}"
          class="my-0 mx-3 bg-primary opacity-0 rounded-3xl h-5 w-5 flex justify-center items-center"
        >
          <span class="text-white">
            {{ selectedOptions.length }}
          </span>
        </div>
        <div class="h-6 w-6 ml-auto">
          <Icon
            v-if="optionsOpen"
            class="transition ease-out duration-150 pointer-events-none"
            path="Close"
            :width="24"
            :height="24"
          />
          <Icon
            v-else
            class="transition ease-out duration-150 pointer-events-none"
            path="ChevronDown"
            :width="24"
            :height="24"
          />
        </div>
      </div>
    </div>
    <div
      v-if="validation.$error"
      class="text-red text-sm"
    >
      {{ validation.$errors[0].$message }}
    </div>
    <div
      v-show="optionsOpen"
      ref="comboOptions"
      class="absolute top-full z-10 bg-white border-primary border-solid border rounded-lg w-11/12 md:w-max"
    >
      <input
        v-if="searchAble"
        :id="`${name}-combo-text-input`"
        v-model="searchField"
        class="border-none rounded-lg w-full"
        type="text"
        :placeholder="`Type to search ${label.toLowerCase()}`"
        @keydown.esc="toggleOptions"
        @keydown.shift.tab="toggleOptions"
      >
      <div class="px-3 pt-3 pb-2 max-h-72 overflow-auto">
        <Checkbox
          v-for="option, index in filteredOptions"
          v-show="showComboOption(option.label)"
          :id="`${name}-combo-option-${option.value}`"
          :key="`${name}-combo-option-${option.value}`"
          class="mb-4"
          :tab-index="focusedOptionIndex == index ? 0 : -1"
          :value="multiSelect ? selectedOptions.includes(option.value) : selectedOptions == option.value"
          :name="`${name}-combo-${option.value}`"
          :label="option.label"
          @keydown.up.prevent="listControl(-1)"
          @keydown.down.prevent="listControl(1)"
          @update:value="onChecked(option.value)"
          @keydown.tab="toggleOptions($event, true)"
        />
        <div
          v-if="noOptionsMatch"
          class="mb-4"
        >
          No options match
        </div>
      </div>
    </div>
  </div>
</template>
