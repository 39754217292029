<script setup>

const input = defineModel({type: String});

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  autocomplete: {
    type: String,
    default: "on",
  },
  label: {
    type: String,
    required: true,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  validation: {
    type: Object,
    default: {},
  },
  type: {
    type: String,
    default: 'text',
  },
  inputClass: {
    type: String,
    default: '',
  },
});

const emit = defineEmits('keydown:enter')
</script>

<template>
  <div>
    <label
      :for="name"
      :class="{'sr-only': hideLabel}"
      class="text-xs font-semibold"
    >{{ label }}</label>
    <input
      v-model="input"
      class="py-2 px-3 w-full block border border-solid border-primary-500 rounded-3xl text-primary-900 focus:border-red"
      :class="( validation.$error ? 'ring-1 ring-red' : 'bg-white ') + inputClass"
      :autocomplete="autocomplete"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      @keydown.enter="emit('keydown:enter')"
    >
    <div
      v-if="validation.$error"
      class="text-red text-sm"
    >
      {{ validation.$errors[0].$message }}
    </div>
  </div>
</template>
